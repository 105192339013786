// Ajax
import { retrieveProfile } from 'ajax/profile';

export async function checkLoginStatus() {
  const token = localStorage.getItem('veezu_token');
  if (!token || token === '') return null;
  const profileResponse = await retrieveProfile();

  const returnStatus = { profile: null, isLoggedIn: false };

  if (profileResponse.status === 200) {
    returnStatus.profile = {
      ...profileResponse.data,
      customisation_nonce: profileResponse.headers['customisation-nonce'],
      configuration_nonce: profileResponse.headers['configuration-nonce'],
      customisation_id: profileResponse.headers.customisation
    };

    returnStatus.isLoggedIn = true;

    return returnStatus;
  }
  return null;
}
