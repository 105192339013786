import apiClient from '../apiClient';

// Retrieve User Profile
export async function retrieveProfile() {
  try {
    const request = await apiClient.get('/profile');
    return request;
  } catch (error) {
    return error.request;
  }
}

// Update User Profile
export async function updateProfile(data) {
  try {
    const request = await apiClient.put('/profile/update', data);
    return request;
  } catch (error) {
    return error.request;
  }
}
