import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState } from 'recoil';

// State
import { isLoggedIn as loginState, profileState, configurationState } from 'state';
// Components
import Seo from 'components/Seo';
// Auth Function
import { checkLoginStatus } from 'func/auth';

function IndexPage() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [, setConfiguration] = useRecoilState(configurationState);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }

    // Route to default if available in the profile
    if (profile?.options?.defaults?.module) {
      navigate(profile?.options?.defaults?.module);
      return;
    }
    if (isLoggedIn) {
      navigate('/booking/create/');
      return;
    }
    initialize();
  }, [isLoggedIn]);

  return <Seo title="Veezu - eBooker" />;
}

export default IndexPage;
